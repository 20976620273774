@import 'styles/variables';

.TrophicNodePlay {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: $off-white;
  margin: 6px;
  word-wrap: break-word;
  vertical-align: top;
  outline: 0.5px solid #dddddd;
  box-shadow: 0 0 3px rgba(167, 167, 167, 0.25);
  border-radius: 4px;
  font-family: $font-family-interface;
  transition: all 0.3s;
  cursor: pointer;

  &-fixed-border {
    outline: 3px solid $green;
    transition: none;
  }

  &:hover,
  &.hover:not(.hoverConnected) {
    background-color: #efefef;
  }

  &.fadedOut:not(.hoverConnected) {
    opacity: 0.5;
  }

  &.increase {
    background-color: rgba($blue, 0.2);
  }
  &.decrease {
    background-color: rgba($red, 0.2);
  }
}

.TrophicNodePlayImageWrap {
  height: 65%;
  width: 100%;
  position: relative;
}

.TrophicNodePlayImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.TrophicNodePlayInner {
  position: relative;
  height: 35%;
  display: flex;
  flex-direction: column;

  &-name {
    font-size: $font-size-interface-subtext;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    color: $dark-blue;

    &.positive {
      color: $blue;
    }

    &.negative {
      color: $red;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
    }
  }
}

.TrophicAbundanceSlider {
  margin: 0;
  width: 100%;
  -webkit-appearance: none;
  height: 2px;
  background: #dddddd;
  outline: none;
  position: relative;
  top: -4px;
}

.TrophicNodePlayOldAbundance {
  height: 10px;
  width: 2px;
  position: absolute;
  background-color: #dddddd;
  top: 5px;
}

.TrophicAbundanceAndSettings {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 2px 0;
  width: 100%;
}

.TrophicAbundanceValue {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;

  &.increase {
    color: $blue;
  }
  &.decrease {
    color: $red;
  }
}

.TrophicNodePlayLock img {
  display: block;
  margin-right: 2px;
}

.TrophicNodePlayLock.toggleDisabled img {
  filter: $make-svg-light-gray;
  cursor: auto;
}

.TrophicNodePlaySettingsButton,
.TrophicNodePlayLock {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.TrophicNodePlaySettingsButton img {
  width: 12px;
  height: 12px;
  display: block;
}

.NodeAbundanceSlider {
  &-range {
    display: flex;
    height: 1rem;
    background-color: $dark-blue;
    align-items: center;
    position: relative;
  }

  &-bubble {
    position: absolute;
    height: 20px;
    width: 36px;
    background-color: #102026;
    color: $white;
    padding: 0.5rem;
    top: -22px;
    font-size: $font-size-interface-subtext;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 100;
    cursor: grab;
  }

  &-temp-value {
    height: 100%;
    background: $green;
    position: absolute;
  }

  &-results-value {
    height: 100%;
    position: absolute;
    left: 0;

    &.positive {
      background: $blue;
    }

    &.negative {
      background: $red;
    }
  }

  &-slider {
    margin: 0;
    width: 100%;
    -webkit-appearance: none;
    // This is a little hack to keep using input but
    // allow grabbing the bubble abover the slider
    height: 46px;
    top: -15px;
    background: transparent;
    outline: none;
    position: relative;
    z-index: 101;
    cursor: grab;

    &-handle {
      width: 2px;
      height: 100%;
      background: $off-white;
      position: absolute;
      cursor: grab;
    }
  }

  &-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2px;
    height: 1rem;
    background: transparent;
    cursor: grab;
    border: none;
  }

  &-slider::-moz-range-thumb {
    width: 2px;
    height: 1rem;
    background: transparent;
    cursor: grab;
    border: none;
  }
}

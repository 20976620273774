@import 'styles/variables';

.MatrixListItemEducation {
  display: flex;
  // TODO this might not be the best way here
  width: 20vw;
  min-width: 16rem;
  max-width: 18rem;
  height: 12rem;
  flex-direction: column;
  cursor: pointer;
  gap: 0.625rem;

  &.highlighted {
    opacity: 0.5;
  }

  .svg-fill {
    &-0 svg path {
      fill: #dc7f85;
    }

    &-1 svg path {
      fill: #648d8d;
    }

    &-2 svg path {
      fill: #7b7d67;
    }

    &-3 svg path {
      fill: #f8b799;
    }
  }

  &-background {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }

  &-icon-container {
    display: flex;
    justify-content: space-around;
  }

  &-text-container {
    text-transform: uppercase;
    font-size: $font-size-interface-medium-large;
    font-family: $font-family-interface;
    font-weight: $font-weight-medium;
    color: $off-white;
  }

  &.highlighted {
    background-color: $light-gray;
    .MatrixListItemPublic-image {
      opacity: 0.5;
    }
  }

  &-text-container {
  }
}

@import 'styles/variables';

.MatrixListPublic {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  margin: 1.5rem;
  gap: 1.5rem;

  &-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@import 'styles/variables';

.MatrixListItemPublic {
  display: flex;
  // TODO this might not be the best way here
  width: 30vw;
  min-width: 20rem;
  max-width: 22rem;
  height: 20rem;
  flex-direction: column;
  cursor: pointer;
  gap: 0.625rem;

  &.highlighted {
    background-color: $light-gray;
    .MatrixListItemPublic-image {
      opacity: 0.5;
    }
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    font-size: $font-size-interface-medium;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    height: 5.5rem;
    gap: 0.25rem;
  }

  &-text-title {
    font-size: $font-size-interface-medium-large;
    font-family: $font-family-interface;
    font-weight: $font-weight-medium;
  }

  &-text-description {
    font-size: $font-size-interface-medium;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-image {
    flex-grow: 1;
    height: 0;
    width: 100%;
    margin-right: 0.5rem;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      background-color: #e2e8f0;
    }
  }
}

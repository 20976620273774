.MultiRange {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &-range-selector {
    flex-grow: 1;
  }

  &-range-number {
    width: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

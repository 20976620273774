@import 'styles/variables';

.TrophicNode {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: $off-white;
  margin: 6px;
  word-wrap: break-word;
  vertical-align: top;
  outline: 0.5px solid #dddddd;
  box-shadow: 0 0 3px rgba(167, 167, 167, 0.25);
  border-radius: 4px;
  font-family: $font-family-interface;
  transition: all 0.3s;
  position: relative;

  &-fixed-border {
    outline: 3px solid $green;
    transition: none;
  }

  &:hover,
  &.hover:not(.hoverConnected) {
    background-color: #efefef;
  }

  &.fadedOut:not(.hoverConnected) {
    opacity: 0.5;
  }

  &.increase {
    background-color: rgba($blue, 0.2);
  }
  &.decrease {
    background-color: rgba($red, 0.2);
  }

  &-settings-button-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.25rem;
  }

  &-settings-button {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: $white-hover;
    }

    .Icon {
      margin-left: unset;
    }
  }
}

.TrophicNodeImageWrap {
  height: 65%;
  width: 100%;
  position: relative;
}

.TrophicNodeImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.TrophicNodeInner {
  position: relative;
  height: 35%;
  display: flex;
  flex-direction: column;

  &-name {
    font-size: $font-size-interface-subtext;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
    margin-right: 6px;
    color: $dark-blue;

    &.positive {
      color: $blue;
    }

    &.negative {
      color: $red;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
    }
  }
}

.TrophicAbundanceSlider {
  margin: 0;
  width: 100%;
  -webkit-appearance: none;
  height: 2px;
  background: #dddddd;
  outline: none;
  position: relative;
  top: -4px;
}

.TrophicNodeOldAbundance {
  height: 10px;
  width: 2px;
  position: absolute;
  background-color: #dddddd;
  top: 5px;
}

.TrophicAbundanceAndSettings {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 2px 0;
  width: 100%;
}

.TrophicAbundanceValue {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;

  &.increase {
    color: $blue;
  }
  &.decrease {
    color: $red;
  }
}

.NodeAbundance {
  &-range {
    display: flex;
    height: 1rem;
    background-color: $dark-blue;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: $font-size-interface-subtext;
    color: $white;
  }

  &-temp-value {
    height: 100%;
    background: $green;
    position: absolute;
  }

  &-results-value {
    height: 100%;
    position: absolute;
    left: 0;

    &.positive {
      background: $blue;
    }

    &.negative {
      background: $red;
    }
  }
}
